// LocationsWidget.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import LocationMarker from './LocationMarker';

function LocationsWidget() {
    const [zipCode, setZipCode] = useState('10001');
    const [locations, setLocations] = useState([]);
    const [storeType, setStoreType] = useState('');
    const [page, setPage] = useState(0);
    const [activeMarkerId, setActiveMarkerId] = useState(null);  // Store the ID of the active marker


    const defaultMapCenter = { lat: 40.7128, lng: -74.0060 };

    const mapStyles = [
        {
            "featureType": "poi.business",
            "stylers": [{ "visibility": "off" }]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text",
            "stylers": [{ "visibility": "off" }]
        },
        {
            "featureType": "poi.school",
            "stylers": [{ "visibility": "off" }]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [{ "visibility": "off" }]
        },
        {
            "featureType": "poi",
            "elementType": "labels.icon",
            "stylers": [{ "visibility": "off" }]
        }
    ];

    const [errorMessage, setErrorMessage] = useState('');

    const handleZipChange = (e) => {
        setZipCode(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchLocations();
    };

    const handleStoreTypeChange = (type) => {
        setStoreType(type);
        setPage(0); // Reset page to 0 when storeType changes
        fetchLocations(zipCode, type, 0); // Fetch locations with the new store type
    };

    const handlePrevPage = () => {
        setPage(Math.max(0, page - 1));
    };

    const handleNextPage = () => {
        setPage(page + 1);
    };

    const handleMarkerClick = (id) => {
        setActiveMarkerId(id);
    };

    const handleInfoWindowClose = () => {
        setActiveMarkerId(null);
    };

    const storeTypeLabels = {
        '': 'All',
        '03': 'Retailers',
        '23': 'Restaurants',
        // '24': 'Cafes',
        // '26': '26',
        '28': 'Hotels',
        '32': 'Bars'
    };

    const storeTypes = [
        { type: '', label: 'All' },
        { type: '03', label: 'Retailers' },
        { type: '23', label: 'Restaurants' },
        // { type: '24', label: 'Grocery Stores' },
        // { type: '26', label: 'Liquor Stores' },
        { type: '28', label: 'Hotels' },
        { type: '32', label: 'Bars' }
    ];

    // http://localhost:3001/locations


    const fetchLocations = async (zip = zipCode, type = storeType, currentPage = page) => {
        // console.log(`Fetching locations for zip: ${zip}, storeType: ${type}, page: ${currentPage}`);
        try {
            const response = await axios.get(`/locations`, {
                params: {
                    zip: zip,
                    miles: 10,
                    storeType: type,
                    page: currentPage,
                    pagesize: 400,
                }
            });
            console.log('Locations:', response.data);
            setLocations(response.data);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                setErrorMessage(error.response.data.message || 'You have made too many requests. Please try again later.');
            } else {
                setErrorMessage('Failed to fetch locations.'); // Generic error message for other errors
            }
            console.error('Failed to fetch locations:', error);
        }
    };

    useEffect(() => {
        fetchLocations();
    }, [page, storeType]); // This will re-trigger when any of these change


    return (
        <div>
            <div className="flex flex-col-reverse md:flex-row min-h-screen">
                <div className="w-full md:w-1/3 p-4 space-y-4">
                    <div>
                        <div className="flex flex-wrap justify-center gap-2 mb-4">

                            {storeTypes.map(({ type, label }) => (
                                <button
                                    key={type}
                                    onClick={() => handleStoreTypeChange(type)}
                                    className={`px-3 py-1 ${storeType === type ? 'bg-[#f05023] text-white' : 'border border-[#f05023] text-[#f05023] hover:bg-[#f05023] hover:text-white'}`}
                                >
                                    {label}
                                </button>
                            ))}
                        </div>
                        <form onSubmit={handleSubmit} className="mb-4">
                            {errorMessage && <div className="text-red-500 text-center mb-2">{errorMessage}</div>}
                            <label className="block mb-2">
                                <strong>ZIP Code:</strong>
                                <input type="text" value={zipCode} onChange={handleZipChange} placeholder="Enter ZIP Code" className="mt-1 p-2 border border-gray-300 w-full" />
                            </label>
                            <button type="submit" className="w-full px-4 py-2 bg-[#f05023] text-white hover:bg-[#f47e5c]">Search</button>
                        </form>
                        {Array.isArray(locations) && locations.length > 0 ? (
                            <div className="h-96 overflow-y-auto">
                                <ul>
                                    {Array.isArray(locations) && locations.map((location, index) => (
                                        <li key={index} className="p-3 border border-[#D5D0CD] mb-4">
                                            <span class="bg-[#F3F2F3] block p-4">
                                                <strong>{location.dba}</strong><br />
                                                {location.address}<br />
                                                <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location.address)}`} target="_blank" rel="noopener noreferrer" className="text-[#f05023] hover:text-[#f47e5c]">
                                                    Directions
                                                </a><br />
                                                Phone: <a href={`tel:+1${location.phone}`} className="text-[#f05023] hover:text-[#f47e5c]">{location.phone}</a>
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="w-full md:w-2/3">
                    <LoadScript
                        googleMapsApiKey="AIzaSyDzIaO3mnziwY4xAXAuJ-pcfSKTO12JFO0"
                        loadingElement={<div>Loading...</div>}
                        async
                        defer>

                        <GoogleMap
                            mapContainerStyle={{ height: "100%", minHeight: "80vh", width: "100%" }}
                            zoom={12}
                            center={locations.length > 0 ? { lat: parseFloat(locations[0].latitude), lng: parseFloat(locations[0].longitude) } : defaultMapCenter}
                            options={{ styles: mapStyles }}
                        >
                            {locations.map((location, index) => (
                                <LocationMarker
                                    key={`${location.id}-${index}`}
                                    location={location}
                                    isActive={activeMarkerId === `${location.id}-${index}`}
                                    onClick={() => setActiveMarkerId(`${location.id}-${index}`)}
                                    onClose={() => setActiveMarkerId(null)}
                                />
                            ))}
                        </GoogleMap>


                    </LoadScript>
                </div>
            </div>
        </div>
    );
}

export default LocationsWidget;
