// LocationMarker.js
import React from 'react';
import { Marker, InfoWindowF } from '@react-google-maps/api';

const LocationMarker = ({ location, isActive, onClick, onClose }) => {
    return (
        <Marker
            position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }}
            onClick={onClick}
        >
            {isActive && (
                <InfoWindowF onCloseClick={onClose}>
                    <div>
                        <h2><strong>{location.dba}</strong></h2>
                        <div>{location.address}</div>
                        <div>
                            <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location.address)}`} target="_blank" rel="noopener noreferrer" className="text-[#f05023] hover:text-[#f47e5c]">
                                Directions
                            </a>
                        </div>
                        <div>Phone: <a href={`tel:+1${location.phone}`} className="text-[#f05023] hover:text-[#f47e5c]">{location.phone}</a></div>
                    </div>
                </InfoWindowF>
            )}
        </Marker>
    );
};

export default LocationMarker;
